import React from "react";
import "./Loader.css";

const Loader = ({ fullscreen }) => {
  return (
    <div className={`main ${fullscreen ? "fullscreen" : ""}`}>
      <div>
        <svg
          width="205"
          height="40"
          viewBox="0 0 205 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_15_251)">
            <path
              d="M204.196 2.25261C204.164 2.22043 204.132 2.18825 204.1 2.18825C203.071 1.76991 194.938 0.289622 193.299 0.965406C192.688 1.22285 192.174 2.96058 191.723 6.30732C191.498 7.91633 191.273 10.8769 191.081 13.7731C190.92 15.9936 190.759 18.0853 190.63 19.4047L190.47 20.9493L189.312 19.9196C188.38 19.115 186.998 17.8922 185.198 16.3154C184.908 16.0579 184.49 16.0579 184.169 16.2832C182.047 17.9566 180.504 19.2116 179.508 20.0161L178.415 20.9171L178.222 19.5012C178.158 18.9541 177.932 16.7015 177.707 14.1271C177.418 10.8769 177.065 7.24055 176.936 6.27514C176.55 3.70072 176.068 1.02977 175.296 0.933226C172.66 0.579244 169.864 0.901046 167.967 1.22285C164.945 1.73773 164.334 2.15607 164.238 2.25261V2.28479C164.077 2.63878 163.981 3.50764 163.917 4.85921C164.302 5.24537 164.688 5.6959 165.074 6.11424C167.131 8.6243 168.385 11.4562 168.835 14.5776C168.964 15.4143 169.221 17.3773 169.221 19.4047C169.189 21.4642 168.899 23.6203 168.739 24.7144C168.321 27.3532 167.421 29.7345 166.102 31.8584C166.745 35.173 167.324 37.4899 167.935 38.9702C167.935 38.9702 168.353 39.8391 173.046 39.4208C175.907 39.1633 179.058 38.5519 180.279 38.2945C180.569 38.2301 180.858 38.0692 181.019 37.8117C181.404 37.2969 183.012 34.3363 183.687 33.0491C183.912 32.6307 184.073 32.3411 184.105 32.2768L184.78 31.1183L185.487 32.2768C185.519 32.3411 185.648 32.5664 185.937 33.0813C186.644 34.3363 188.316 37.2647 188.702 37.7796C188.862 38.0048 189.152 38.1979 189.666 38.3588C190.952 38.8093 193.845 39.3564 196.706 39.4851C200.435 39.646 201.753 38.9702 201.978 38.5841C202.396 37.7474 204.164 28.5438 204.775 19.9196C205.579 7.69107 204.325 2.73532 204.196 2.25261Z"
              fill="#C75227"
              className="svg_element_1"
            ></path>
            <path
              d="M167.581 19.4368C167.581 17.506 167.324 15.6396 167.227 14.8351C166.842 12.0354 165.717 9.46098 163.884 7.24055C163.852 7.20837 163.852 7.17619 163.82 7.17619C163.595 6.88656 163.338 6.62912 163.081 6.3395C162.823 6.08206 162.566 5.79244 162.277 5.56718C159.384 2.89622 155.59 1.35157 150.961 0.933226C150.415 0.901046 149.868 0.868866 149.354 0.868866C145.496 0.868866 142.024 2.15607 139.035 4.66613C138.135 5.40628 137.363 6.21078 136.656 7.04747C137.974 9.17136 138.81 11.8101 139.003 14.5776C139.067 15.5752 138.874 16.3797 138.424 17.0233C137.877 17.7313 137.074 18.214 136.206 18.2784L132.445 18.6002C132.413 19.1472 132.38 19.6943 132.38 20.2735C132.38 20.6597 132.38 21.0459 132.413 21.432L135.917 21.786C136.945 21.9147 137.877 22.494 138.456 23.3307C138.97 24.0708 139.131 25.004 138.938 26.1625C138.617 28.3508 137.685 30.6356 136.367 32.6951C139.067 36.0418 142.699 38.1335 147.168 38.8737C152.118 39.7104 156.523 38.6806 160.252 35.8488C161.891 34.5937 163.241 33.1778 164.302 31.601C164.527 31.2792 164.72 30.9252 164.945 30.5712C165.17 30.185 165.363 29.7989 165.556 29.4127C166.263 27.9002 166.777 26.2912 167.067 24.5213C167.26 23.4594 167.549 21.3998 167.581 19.4368ZM155.333 22.7836C155.14 23.9421 154.626 24.9718 153.79 25.8085C152.954 26.6774 151.893 27.1601 150.64 27.2888C150.35 27.321 150.093 27.3532 149.836 27.3532C148.164 27.3532 146.943 26.6452 145.946 25.1327C144.307 22.6549 144.082 15.8327 146.718 13.7088C147.811 12.8399 149.129 12.5181 150.736 12.679C152.022 12.8399 153.083 13.4191 153.854 14.4811C155.751 16.9911 155.558 21.432 155.333 22.7836Z"
              fill="#C75227"
              className="svg_element_2"
            ></path>
            <path
              d="M137.138 24.1995C136.817 23.7168 136.335 23.4272 135.756 23.3628L132.541 23.041L131.738 22.9445L130.934 22.8479L125.694 22.3009C124.987 22.2043 124.28 22.5583 123.99 23.2019C123.958 23.2663 123.862 23.5237 123.829 23.7168C123.604 24.4248 123.444 24.9075 123.122 25.2293C122.351 26.0016 121.451 26.42 120.422 26.4843C118.911 26.5487 117.625 26.1625 116.661 25.2936C115.439 24.1995 114.764 22.4296 114.668 20.0483C114.571 17.6348 115.439 15.6396 117.175 14.3846C117.979 13.8053 118.847 13.4835 119.779 13.4835C120.036 13.4835 120.293 13.5157 120.55 13.5479C121.933 13.7731 122.962 14.3524 123.669 15.2856C123.829 15.4787 123.926 15.7683 124.151 16.4119C124.215 16.5406 124.28 16.7337 124.28 16.7981C124.505 17.2486 124.987 17.5704 125.533 17.6026C125.598 17.6026 125.662 17.6026 125.726 17.6026L130.966 17.1521L131.77 17.0877L132.573 17.0233L136.013 16.7337C136.431 16.7015 136.849 16.4763 137.106 16.0901C137.331 15.7683 137.428 15.35 137.363 14.7385C137.203 12.4859 136.56 10.2977 135.563 8.46339C135.434 8.20595 135.274 7.94851 135.113 7.69107C134.952 7.46581 134.792 7.20837 134.631 7.01528C134.534 6.88656 134.47 6.79002 134.374 6.69348C131.191 2.83186 126.691 0.643603 120.968 0.12872C120.39 0.0643597 119.843 0.0643597 119.265 0.0643597C115.182 0.0643597 111.549 1.41593 108.399 4.08689C107.049 5.21319 105.924 6.46822 104.959 7.81979C105.024 9.01046 105.056 10.362 105.056 11.9067C105.056 14.1915 104.959 16.9268 104.734 20.1126C104.509 23.4916 104.059 27.0636 103.609 30.2494C104.027 31.0539 104.509 31.8262 105.056 32.5664C107.949 36.5245 111.935 38.9381 116.95 39.7747C118.107 39.9678 119.265 40.0644 120.358 40.0644C124.183 40.0644 127.655 38.9059 130.677 36.5889C132.091 35.5269 133.313 34.2397 134.341 32.8238C134.502 32.5986 134.663 32.3733 134.824 32.1159C134.984 31.8584 135.145 31.6331 135.274 31.3757C136.302 29.638 137.01 27.8037 137.267 26.0016C137.492 25.1649 137.428 24.6179 137.138 24.1995Z"
              fill="#C75227"
              className="svg_element_3"
            ></path>
            <path
              d="M103.448 8.68866C103.416 8.17377 103.416 7.72325 103.384 7.27273C103.159 4.02253 102.773 2.51006 102.709 2.25261C102.677 2.22043 102.645 2.18825 102.613 2.18825C101.584 1.76991 93.4508 0.289622 91.8113 0.965407C91.2005 1.22285 90.6861 2.96058 90.2361 6.30732C90.0111 7.91633 89.786 10.8769 89.5931 13.7731C89.4324 15.9936 89.2717 18.0853 89.1431 19.4047L88.9823 20.9493L87.8251 19.9195C86.925 19.115 85.5105 17.8922 83.7103 16.3154C83.421 16.0579 83.0031 16.0579 82.6816 16.2832C80.5599 17.9566 79.0169 19.2116 78.0203 20.0161L76.9273 20.9171L76.7344 19.5012C76.638 18.9863 76.4451 16.7337 76.1879 14.1593C75.8986 10.9413 75.545 7.27273 75.4164 6.30732C75.0307 3.7329 74.5485 1.06195 73.7769 0.965407C71.1409 0.611424 68.3441 0.933226 66.4475 1.25503C63.4257 1.76991 62.8149 2.18825 62.7184 2.28479V2.31698C62.5577 2.67096 62.4613 3.53982 62.397 4.89139C62.7827 5.27755 63.1685 5.72808 63.5543 6.14642C65.6116 8.65648 66.8654 11.4883 67.3154 14.6098C67.444 15.4465 67.7012 17.4095 67.669 19.4368C67.6369 21.4964 67.3476 23.6525 67.1868 24.7466C66.7689 27.3854 65.8688 29.7667 64.5508 31.8906C65.1937 35.2051 65.7724 37.5221 66.3832 39.0024C66.3832 39.0024 66.8011 39.8713 71.4945 39.4529C74.3556 39.1955 77.506 38.5841 78.7275 38.3266C79.0169 38.2623 79.3062 38.1014 79.4669 37.8439C79.8527 37.329 81.4279 34.3685 82.1351 33.0813C82.3601 32.6629 82.5208 32.3733 82.553 32.3089L83.2281 31.1504L83.9353 32.3089C83.9675 32.3733 84.096 32.5985 84.3854 33.1134C85.0926 34.3685 86.7642 37.2969 87.15 37.8117C87.3107 38.037 87.6 38.2301 88.1144 38.391C89.4003 38.8415 92.2935 39.3886 95.1545 39.5173C98.8836 39.6782 100.202 39.0024 100.427 38.6162C100.652 38.1335 101.359 34.819 102.034 30.4747C102.098 29.9919 102.163 29.5092 102.259 29.0265C102.323 28.4795 102.42 27.9002 102.484 27.321C102.805 24.9718 103.063 22.4618 103.223 19.9839C103.513 16.0579 103.577 12.8721 103.513 10.362C103.481 9.71842 103.481 9.20354 103.448 8.68866Z"
              fill="#C75227"
              className="svg_element_4"
            ></path>
            <path
              d="M66.0616 19.4368C66.0616 17.506 65.8045 15.6396 65.708 14.8351C65.3223 12.0354 64.1971 9.46098 62.3648 7.24055C62.3326 7.20837 62.3326 7.17619 62.3005 7.17619C62.0754 6.88656 61.8183 6.59694 61.5611 6.3395C61.3039 6.08206 61.0467 5.79244 60.7574 5.56718C57.8642 2.89622 54.0709 1.35157 49.4418 0.933226C48.8953 0.901046 48.3488 0.868866 47.8344 0.868866C43.9768 0.868866 40.505 2.15607 37.5153 4.66613C36.6152 5.40628 35.8437 6.21078 35.1364 7.04747C36.4545 9.17136 37.2903 11.8101 37.5153 14.5776C37.5796 15.5752 37.3867 16.3797 36.9367 17.0233C36.4223 17.7313 35.5865 18.214 34.7185 18.2784L30.9574 18.6002C30.9252 19.1472 30.8931 19.6943 30.8931 20.2735C30.8931 20.6597 30.8931 21.0459 30.9252 21.432L34.4292 21.786C35.4579 21.9147 36.3902 22.494 36.9688 23.3307C37.4832 24.0708 37.6439 25.004 37.451 26.1625C37.1295 28.3508 36.2294 30.6356 34.8793 32.6951C37.5796 36.0418 41.2122 38.1335 45.6806 38.8737C50.6312 39.7104 55.0353 38.6806 58.7643 35.8488C60.4038 34.5937 61.754 33.1778 62.8148 31.601C63.0398 31.2792 63.2327 30.9252 63.4578 30.5712C63.6828 30.185 63.8757 29.7989 64.0685 29.4127C64.7758 27.9002 65.2901 26.2912 65.5794 24.5213C65.7723 23.4594 66.0616 21.3998 66.0616 19.4368ZM53.8137 22.7836C53.6208 23.9421 53.1065 24.9718 52.2707 25.8085C51.4349 26.6774 50.374 27.1601 49.1203 27.2888C48.831 27.321 48.5738 27.3532 48.3166 27.3532C46.645 27.3532 45.4234 26.6452 44.4269 25.1327C42.7874 22.6549 42.5623 15.8327 45.1984 13.7088C46.2914 12.8399 47.6094 12.5181 49.2167 12.679C50.5026 12.8399 51.5634 13.4191 52.335 14.4811C54.2638 16.9911 54.0387 21.432 53.8137 22.7836Z"
              fill="#C75227"
              className="svg_element_5"
            ></path>
            <path
              d="M35.6508 24.1995C35.3293 23.7168 34.8471 23.4272 34.2685 23.3628L31.0538 23.041L30.2501 22.9445L29.4464 22.8479L24.2065 22.3009C23.4993 22.2043 22.7921 22.5583 22.5027 23.2019C22.4706 23.2663 22.3742 23.5237 22.3099 23.7168C22.0848 24.4248 21.9241 24.9075 21.6026 25.2293C20.8311 26.0016 19.931 26.42 18.9023 26.4843C17.3914 26.5487 16.1055 26.1625 15.1411 25.2936C13.9196 24.1995 13.2766 22.4296 13.148 20.0483C13.0516 17.6348 13.9196 15.6396 15.6555 14.3846C16.4592 13.8053 17.3271 13.4835 18.2594 13.4835C18.5165 13.4835 18.7737 13.5157 19.0309 13.5479C20.4132 13.7731 21.4419 14.3524 22.1491 15.2856C22.3099 15.4787 22.4063 15.7683 22.6313 16.4119C22.6956 16.5406 22.7599 16.7337 22.7599 16.7981C22.9849 17.2486 23.4671 17.5704 24.0136 17.6026C24.0779 17.6026 24.1422 17.6026 24.2065 17.6026L29.4464 17.1521L30.2501 17.0877L31.0538 17.0233L34.4935 16.7337C34.9114 16.7015 35.3293 16.4763 35.5865 16.0901C35.8115 15.7683 35.9079 15.35 35.8437 14.7385C35.6829 12.4859 35.04 10.2977 34.0434 8.46339C33.9148 8.20595 33.7541 7.94851 33.5934 7.69107C33.4326 7.46581 33.2719 7.20837 33.1112 7.01529C33.0147 6.88656 32.9504 6.79002 32.854 6.69348C29.7358 2.7675 25.2352 0.579244 19.4809 0.0643604C18.9345 0.0321802 18.3558 0 17.8093 0C13.7267 0 10.0941 1.35157 6.9437 4.02253C3.34326 7.04747 1.15728 10.8447 0.450055 15.2856C0.225027 16.605 0 18.568 0 20.4666C0.0321468 22.7192 0.353615 24.9075 0.578642 25.8729C1.09299 28.1899 2.08954 30.4425 3.63259 32.502C6.5258 36.4602 10.512 38.8737 15.5269 39.7104C16.6842 39.9035 17.8415 40 18.9345 40C22.7599 40 26.2318 38.8415 29.2857 36.5245C30.7002 35.4626 31.9217 34.1754 32.9504 32.7595C33.1112 32.5342 33.2719 32.3089 33.4326 32.0515C33.5934 31.794 33.7541 31.5688 33.8827 31.3113C34.9114 29.5736 35.6186 27.7393 35.8758 25.9372C36.0044 25.1649 35.9079 24.6179 35.6508 24.1995Z"
              fill="#C75227"
              className="svg_element_6"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_15_251">
              <rect
                width="205"
                height="40"
                fill="white"
                className="svg_element_7"
              ></rect>
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default Loader;
