// @ts-nocheck
import logo from "./logo_new.svg";
import "./App.css";
import React, { useEffect, useState } from "react";
import Loader from "./components/Loader/Loader.jsx";
import { TwitterTweetEmbed } from "react-twitter-embed";

function App() {
  const [tweets, setTweets] = useState([]);
  const [page, setPage] = useState(1);
  const [loadedTweets, setLoadedTweets] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [finished, setFinished] = useState(false);
  var formData = new FormData();

  const fetchTweets = (formData) => {
    setIsLoading(true);
    fetch("https://twitterapi.cowcow.io", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.length > 0) {
          setTweets((tweets) => [...tweets, ...json]);
          if (json.length < 10) {
            setFinished(true);
          }
        } else {
          setIsLoading(false);
          setFinished(true);
        }
      });
  };

  useEffect(() => {
    formData.append("action", "tweets");
    formData.append("page", "1");
    fetchTweets(formData);
  }, []);

  useEffect(() => {
    if (
      tweets.length > 0 &&
      (loadedTweets === tweets.length || loadedTweets === page * 10)
    ) {
      setIsLoading(false);
    }
  }, [loadedTweets]);

  useEffect(() => {
    if (page > 1) {
      formData.set("action", "tweets");
      formData.set("page", page);
      fetchTweets(formData);
    }
  }, [page]);

  return (
    <>
      <header>
        <img src={logo} alt="CowCow" />
      </header>
      <div className={`flex ${isLoading ? "loading" : "loaded"}`}>
        {tweets &&
          tweets.map((tweet) => (
            <TwitterTweetEmbed
              key={tweet.id}
              tweetId={tweet.id}
              placeholder={null}
              onLoad={() => setLoadedTweets((loadedTweets) => loadedTweets + 1)}
            />
          ))}
        {page === 1 ? (
          <div className="placeholder-loader">
            {isLoading && <Loader fullscreen />}
          </div>
        ) : (
          <div className="placeholder-loader">{isLoading && <Loader />}</div>
        )}
        <div className="flex">
          {!finished && !isLoading && (
            <div
              onClick={() => setPage((page) => page + 1)}
              className="loadMore"
            >
              Load More
            </div>
          )}
        </div>
      </div>
      <footer>
        <span>&#169; 2023, Cow Cow</span>
        <span>Cow together, vibe together.</span>
      </footer>
    </>
  );
}

export default App;
